@import "./components/styles/btnstyles.scss";
@import "./components/SettingsForm/styles/SettingsForm.scss";
@import "./components/ImagesGroup/ImageGroup.scss";
@import "./components/Modal/Modal.scss";

$gallerysettings-width-form: 50%;
$gallerysettings-width-pics: calc(100% - $gallerysettings-width-form);

:export {
  galleryWidth: $gallerysettings-width-form;
}

.Gallerysettings {
  width: 100%;
  max-height: 80vh;
  height: 80%;
  display: flex;
  flex-direction: column;
  color: white;

  .gallerysettings-counter {
    max-height: 100vh;
    min-height: 70vh;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 10px;
    width: 100%;
  }
}

@media (max-width: 700px) {
  .Gallerysettings {
    align-items: center;
  }
}
