.selectFile {
  width: 100%;
  display: flex;
  flex-direction: row;
  .selectGroup {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: 1.6rem;
      text-align: center;
      margin: 5px 0;
    }
    .form__input-text,
    .form__btn {
      width: 90%;
      padding: 8px 20px;
      margin: 15px 0;
      background-color: black;
      border: none;
      border-bottom: 2px solid orange;
      color: white;
    }
    .select-new-files {
      color: black;
      width: 90%;
      .new-files__control {
        transition: all 1s ease-in-out;
        width: 100%;
      }
      .new-files__menu {
        width: 100%;
      }
    }
  }

  .foldername {
    position: relative;
    display: block;
    height: auto;
    width: 90%;
    margin-top: 15px;
    .form__input-text {
      display: block;
      width: 100%;

      &:focus + span {
        top: -8px;
        font-size: smaller;
      }
    }
    span {
      position: absolute;
      left: 0px;
      top: 6px;
      margin-left: 6px;
      width: fit-content;
      color: white;
      transition: 0.5s ease;
    }
  }
  .form__file-label {
    .form__input {
      background-color: red;
      width: 100%;
      height: 40px;
      padding: 10px;
    }
  }

  .form__btn {
    border: 2px solid orange;
    color: orange;
    background-color: black;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: bold;
    width: 90%;
    &:hover {
      cursor: pointer;
    }
  }
  .btn_send {
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: center;
    height: 50px;
    width: 90%;
    color: orange;
    padding: 10px;
    .btn__text {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 100%;
      line-height: 50px;
      transition: top 0.4s;
    }
    &::after {
      position: absolute;
      top: 100%;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      line-height: 50px;
      background-color: green;
      content: "Send";
      transition: top 0.4s;
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      text-transform: uppercase;
    }

    &:hover::after {
      top: 0;
    }
    &:hover > .btn__text {
      top: -100%;
    }
  }
}

@media (max-width: 700px) {
  .selectFile {
    flex-direction: column;
    .selectGroup {
      width: 100%;
    }
  }
}
