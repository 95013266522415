.gallerysettings-form {
  .newFolderGroup {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 80%;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 700px) {
    .newFolderGroup {
      width: 100%;
    }
  }
  @media (min-width: 700px) {
    .newFolderGroup {
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}
