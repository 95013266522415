.gallerysettings-form {
  .buttons_group {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .form__btn {
      position: relative;
      display: inline-block;
      transition: 0.5s;
      border: 2px solid orange;
      color: orange;
      background-color: black;
      padding: 8px 20px;
      border-radius: 8px;
      font-size: 1.2rem;
      font-weight: bold;
      width: 100%;
      margin: 10px 0;
      overflow: hidden;
      &:hover {
        cursor: pointer;
        border: 2px solid transparent;
      }
      &:hover span {
        position: absolute;
        display: block;
        &:nth-child(1) {
          top: 0;
          left: -100%;
          width: 100%;
          height: 2px;
          background: linear-gradient(90deg, black, red);
          animation: btn-anim1 1s linear infinite;
        }
        &:nth-child(2) {
          top: -100%;
          right: 0;
          width: 2px;
          height: 100%;
          background: linear-gradient(180deg, orange, black);
          animation: btn-anim2 1s linear infinite;
          animation-delay: 0.25s;
        }
        &:nth-child(3) {
          bottom: 0;
          right: -100%;
          width: 100%;
          height: 2px;
          background: linear-gradient(270deg, black, orange);
          animation: btn-anim3 1s linear infinite;
          animation-delay: 0.5s;
        }
        &:nth-child(4) {
          bottom: -100%;
          left: 0;
          width: 2px;
          height: 100%;
          background: linear-gradient(360deg, orange, black);
          animation: btn-anim4 1s linear infinite;
          animation-delay: 0.75s;
        }
      }

      @keyframes btn-anim1 {
        0% {
          left: -100%;
        }
        50%,
        100% {
          left: 100%;
        }
      }
      @keyframes btn-anim2 {
        0% {
          top: -100%;
        }
        50%,
        100% {
          top: 100%;
        }
      }
      @keyframes btn-anim3 {
        0% {
          right: -100%;
        }
        50%,
        100% {
          right: 100%;
        }
      }
      @keyframes btn-anim4 {
        0% {
          bottom: -100%;
        }
        50%,
        100% {
          bottom: 100%;
        }
      }
    }
  }
  @media (max-width: 700px) {
    .buttons_group {
      width: 100%;
      padding: 10px;
    }
  }
}
